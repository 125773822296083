body {
	background: #213885;
}

.qualified {
	color: #3bcf5a;
	font-size: 1rem;
}

.eliminated {
	color: red;
	font-size: 1rem;
}

.table-header {
	color: white;
	font-weight: bold;
	display: flex;
	margin: 10px;
	padding: 5px 20px;
	border-radius: 0.2rem;
}

.table-row {
	color: #181847;
	font-weight: bold;
	display: flex;
	margin: 10px;
	background: #fff;
	padding: 5px 20px;
	border-radius: 0.2rem;
}

.table-row div,
.table-header div {
	flex: 1;
}

/* .game-container > div {
	width: 48%;
	text-align: center;
	padding: 0.7rem;
	font-weight: bold;
	background: white;
	border-radius: 0.2rem;
	margin: 0.1rem;
} */

.header {
	padding-top: 10px;
	text-align: center;
	color: #fff;
}

h3 {
	padding: 5px 15px;
	color: #fff;
}

.match-number {
	font-weight: 400;
	font-size: 0.8rem;
}

.footer {
	text-align: center;
	color: #ccc;
	padding: 2rem 0.4rem 1rem;
}

.scenarios {
}

/* Add this to your App.css */

.scenarios-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px;
}

@media (min-width: 768px) {
	/* Adjusts for tablets and desktops */
	.scenarios-section {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.scenarios-section > div {
		flex: 1;
		margin-right: 20px; /* Adds some spacing between the controls */
	}
	.scenarios-section > div:last-child {
		margin-right: 0; /* Removes margin from the last element */
	}
}

.scenario {
	width: 100%;
}

.game-and-scenario {
	display: flex;
	padding: 5px 15px;
}
.game-and-scenario div {
	/* width: 100%; */
}

.gamecontainer {
	width: 100%;
	text-align: center;
}

.gamecontainer > div {
	width: 100%; /* Shows three games per row instead of two */
}

.disclaimer {
	padding: 1rem;
	color: red;
	background-color: rgba(255, 255, 255, 0.2);
	font-weight: bold;
}

.note {
	font-weight: 400;
	font-size: 0.8rem;
	color: red;
}

.twitter-follow-button {
	margin-left: 20px;
}

.scenarios-section {
	background-color: #fff;
	padding: 5px 15px;
	margin: 1rem;
	border-radius: 0.2rem;
}

.sub-header {
	color: white;
	padding: 1em 1em 0 1.5em;
	display: flex;
	justify-content: space-between;
}
/* Game Component Styles */
.game-container {
	background-color: #ffffff;
	color: #000;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 20px;
	margin: 20px auto;
	max-width: 800px;
}

.match-number {
	font-size: 0.85rem;
	color: #666;
	margin-bottom: 10px;
}

.game-team {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
}

.game-action {
	flex-basis: 30%;
	text-align: center;
}

.game-inputs {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	margin-left: 20px;
}

.game-input {
	width: 45%; /* Each input takes roughly half the space of inputs container */
}

/* Enhancements for readability and interaction */
input,
button {
	padding: 8px 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 1rem;
}

button {
	background-color: #007bff;
	color: white;
	border: none;
	cursor: pointer;
}

button:hover {
	background-color: #0056b3;
}

.sitenote {
	bottom: 0;
	background-color: rgba(255, 255, 0, 0.8);
	padding: 0 5px;
	text-align: center;
}

.thank-you {
	color: white;
	background-color: rgba(255, 255, 0, 0.1);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 170px;
}
